import React, { useEffect } from 'react';
import './style_fail.css';

const ErrorPagePw = () => {
  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;

    document.body.style.backgroundColor = '#e6e4e4'; // Set your desired color

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  return (
    <section>
      <div className="rt-container">
        <div className="col-rt-12">
          <div className="Scriptcontent">
            <div id="card" className="animated fadeIn">
              <div id="upper-side" style={{ background: '#c22727' }}>
                <svg
                  version="1.1"
                  id="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  xmlSpace="preserve"
                >
                  <g fill="none">
                    <line x1="90.486" y1="85.353" x2="128.486" y2="123.353" stroke="#fff" strokeWidth="5" strokeLinecap="round" />
                    <line x1="128.486" y1="85.353" x2="90.486" y2="123.353" stroke="#fff" strokeWidth="5" strokeLinecap="round" />
                  </g>
                  <circle fill="none" stroke="#ffffff" strokeWidth="5" strokeMiterlimit="10" cx="109.486" cy="104.353" r="32.53" />
                </svg>
                <h3 id="status">Error</h3>
              </div>
              <div id="lower-side">
                <p id="message">There was an error resetting your password. Please try again. If the error persists, contact support.</p>
                <a href="/" id="contBtn" style={{ background: '#c22727' }}>
                  Continue
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPagePw;
