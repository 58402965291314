// material-ui
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// ==============================|| Loader ||============================== //

const Loader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh" // Adjust the height as needed, 100vh will make it take the full viewport height
  >
    <CircularProgress />
  </Box>
);

export default Loader;
