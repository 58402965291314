// material-ui
import {
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
  TextField,
  Snackbar,
  InputAdornment,
  FormHelperText
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { EyeOutlined, EyeInvisibleOutlined, EditOutlined } from '@ant-design/icons';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newName, setNewName] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [isLoadingName, setIsLoadingName] = useState(false);
  const [isLoadingPhone, setIsLoadingPhone] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const token = sessionStorage.getItem('dialvoxToken');

  function parseJwt(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error('Error parsing JWT', e);
      return null;
    }
  }

  const payload = parseJwt(token);
  const userId = payload ? payload.userId : null;

  const handleNameEdit = () => {
    setNewName(userData.name);
    setNameDialogOpen(true);
  };

  const handleNameConfirm = async () => {
    setIsLoadingName(true);
    try {
      await fetch(`https://api.dialvox.ai/api/user/profileUpdate/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name: newName,
          phone: userData.phone
        })
      });
      setSnackbarMessage('Name updated');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Failed to update name:', error);
      setSnackbarMessage('Failed to update name');
      setOpenSnackbar(true);
    } finally {
      setNameDialogOpen(false);
      setIsLoadingName(false);
    }
  };

  const handlePhoneEdit = () => {
    setNewPhone(userData.phone);
    setPhoneDialogOpen(true);
    window.location.reload();
  };

  const handlePhoneConfirm = async () => {
    setIsLoadingPhone(true);
    try {
      await fetch(`https://api.dialvox.ai/api/user/profileUpdate/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          name: userData.name,
          phone: newPhone
        })
      });
      setSnackbarMessage('Phone updated');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Failed to update phone:', error);
      setSnackbarMessage('Failed to update phone');
      setOpenSnackbar(true);
    } finally {
      setPhoneDialogOpen(false);
      setIsLoadingPhone(false);
      window.location.reload();
    }
  };

  const handlePasswordEdit = () => {
    setPasswordDialogOpen(true);
  };

  const handlePasswordConfirm = async () => {
    setIsLoadingPassword(true);
    if (newPassword !== confirmNewPassword) {
      setErrorText("Passwords don't match");
    }
    try {
      const result = await fetch(`https://api.dialvox.ai/api/user/changePassword/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          currentPassword: currentPassword,
          newPassword: newPassword
        })
      });
      if (result.status === 400) {
        setSnackbarMessage('Current password is incorrect');
        setOpenSnackbar(true);
        return;
      }
      setSnackbarMessage('Password updated');
      setErrorText('');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Failed to update password:', error);
      setSnackbarMessage('Failed to update password');
      setOpenSnackbar(true);
    } finally {
      setPasswordDialogOpen(false);
      setIsLoadingPassword(false);
      setCurrentPassword('');
      setConfirmNewPassword('');
      setNewPassword('');
    }
  };

  useEffect(() => {
    const fetchOptions = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://api.dialvox.ai/api/user/${userId}`, fetchOptions);
        const data = await response.json();
        setUserData(data.data[0]);
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [token, userId]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h3">Profile</Typography>
      </Grid>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Email:</strong> {userData?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="body1">
              <strong>Name:</strong> {userData?.name}
            </Typography>
            <IconButton onClick={handleNameEdit}>
              <EditOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Typography variant="body1">
              <strong>Phone:</strong> {userData?.phone}
            </Typography>
            <IconButton onClick={handlePhoneEdit}>
              <EditOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 1,
                mr: 1,
                height: '48px',
                width: 'auto'
              }}
              onClick={handlePasswordEdit}
            >
              Change Password
            </Button>
          </Grid>
        </>
      )}

      <Dialog open={nameDialogOpen} onClose={() => setNameDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit name</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField id="new-name" label="New Name" value={newName} onChange={(e) => setNewName(e.target.value)} fullWidth />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNameDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleNameConfirm} color="primary" disabled={isLoadingName}>
            {isLoadingName ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={phoneDialogOpen} onClose={() => setPhoneDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit phone number</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField id="new-phone" label="New phoone" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} fullWidth />
            <FormHelperText sx={{ mb: 3 }}>Phone format: 00 + countryCode + regionCode + phone</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPhoneDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePhoneConfirm} color="primary" disabled={isLoadingPhone}>
            {isLoadingPhone ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit password</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              id="current-password"
              label="Current Password"
              type={showCurrentPassword ? 'text' : 'password'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowCurrentPassword((prev) => !prev)} edge="end">
                      {showCurrentPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id="new-password"
              label="New Password"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNewPassword((prev) => !prev)} edge="end">
                      {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              id="confirm-new-password"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              fullWidth
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword((prev) => !prev)} edge="end">
                      {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormHelperText sx={{ mb: 3, color: 'red' }}>{errorText}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePasswordConfirm} color="primary" disabled={isLoadingPassword}>
            {isLoadingPassword ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Grid>
  );
};

export default ProfilePage;
