import React, { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import Loadable from 'components/Loadable';
import ProfilePage from 'pages/profile/index';
import ErrorPageActivate from 'pages/responsePages/registration/Fail/index';
import ErrorPagePw from 'pages/responsePages/passwordReset/Fail/index';
import SuccessPageActivate from 'pages/responsePages/registration/Success/index';
import SuccessPagePw from 'pages/responsePages/passwordReset/Success/index';
import NotFoundPage from 'pages/NotFoundPage/index';

// Layouts
const MinimalLayout = Loadable(lazy(() => import('layout/MinimalLayout')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));

// Pages
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const CallsDefault = Loadable(lazy(() => import('pages/calls')));
const KeysDefault = Loadable(lazy(() => import('pages/keys')));
const CreditsDefault = Loadable(lazy(() => import('pages/credit')));
const ProductsPage = Loadable(lazy(() => import('pages/stripe')));

// Authentication check function
function isAuthenticated() {
  const jwtFromSession = sessionStorage.getItem('dialvoxToken');
  return !!jwtFromSession;
}

// Combined Routes
function ThemeRoutes() {
  const authenticated = isAuthenticated();

  const routes = [
    {
      path: '/',
      element: authenticated ? <MainLayout /> : <Navigate to="/login" replace />,
      children: authenticated
        ? [
            { path: '/', element: <DashboardDefault /> },
            {
              path: 'dashboard',
              children: [
                { path: 'default', element: <DashboardDefault /> },
                { path: 'calls', element: <CallsDefault /> },
                { path: 'keys', element: <KeysDefault /> },
                { path: 'credits', element: <CreditsDefault /> }
              ]
            },
            { path: 'buy', element: <ProductsPage /> },
            { path: 'profile', element: <ProfilePage /> },
            { path: '*', element: <Navigate to="/" replace /> }
          ]
        : []
    },
    {
      path: '/',
      element: !authenticated ? <MinimalLayout /> : <Navigate to="/" replace />,
      children: !authenticated
        ? [
            { path: 'login', element: <AuthLogin /> },
            { path: 'register', element: <AuthRegister /> },
            { path: 'successActivate', element: <SuccessPageActivate /> },
            { path: 'errorActivate', element: <ErrorPageActivate /> },
            { path: 'resetSuccess', element: <SuccessPagePw /> },
            { path: 'resetError', element: <ErrorPagePw /> },
            { path: '*', element: <Navigate to="/login" replace /> }
          ]
        : []
    },
    { path: '*', element: <NotFoundPage /> }
  ];

  return useRoutes(routes);
}

export default ThemeRoutes;
